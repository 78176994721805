import { Module, MutationTree, ActionTree, GetterTree, Commit, Dispatch } from 'vuex';
import { RootStore } from '@/features/core/store';

type ProjectCreateInterfaceState = {
  showPropertyInformationCopySelection: boolean;
};

// Initial state of the project
const state: ProjectCreateInterfaceState = {
  showPropertyInformationCopySelection: false,
};

// Mutations
const mutations: MutationTree<ProjectCreateInterfaceState> = {
  setShowPropertyInformationCopySelection(state, showPropertyInformationCopySelection: boolean) {
    state.showPropertyInformationCopySelection = showPropertyInformationCopySelection;
  },
};

// Actions
const actions: ActionTree<ProjectCreateInterfaceState, any> = {};

// Getters
const getters: GetterTree<ProjectCreateInterfaceState, RootStore> = {
  showPropertyInformationCopySelection: (state) => state.showPropertyInformationCopySelection,
};

// Export the module
export const ProjectCreateInterfaceModule: Module<ProjectCreateInterfaceState, any> = {
  state,
  mutations,
  actions,
  getters,
};
